import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../src/app/pages/login/login.module').then( m => m.LoginPageModule)
  },
  { path: 'home',
    loadChildren: () => import('../src/app/pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'enter-by-email',
    loadChildren: () => import('../src/app/pages/enter-by-email/enter-by-email.module').then( m => m.EnterByEmailPageModule)
  },
  {
    path: 'access-mobile',
    loadChildren: () => import('../src/app/pages/access-mobile/access-mobile.module').then( m => m.AccessMobilePageModule)
  },
  {
    path: 'admin-users',
    children : [
      {
        path : '',
        loadChildren: () => import('../src/app/pages/admin-users/admin-users.module').then( m => m.AdminUsersPageModule)
      },
      {
        path : 'add',
        loadChildren: () => import('../src/app/pages/add-user/add-user.module').then( m => m.AddUserPageModule)
      },
      {
        path : 'edit/:idCliente',
        loadChildren: () => import('../src/app/pages/admin-user-edit/admin-user-edit.module').then( m => m.AdminUserEditPageModule)
      },
    ]
  },
  {
    path: 'personal-data',
    loadChildren: () => import('../src/app/pages/personal-data/personal-data.module').then( m => m.PersonalDataPageModule)
  },
  {
    path: 'personal-questions',
    loadChildren: () => import('../src/app/pages/personal-questions/personal-questions.module').then( m => m.PersonalQuestionsPageModule)
  },
  {
    path: 'cuestionario',
    loadChildren: () => import('../src/app/pages/cuestionario/cuestionario.module').then( m => m.CuestionarioPageModule)
  },
  {
    path: 'seguimiento',
    children : [
      {
        path: '',
        loadChildren: () => import('../src/app/pages/seguimiento/seguimiento.module').then( m => m.SeguimientoPageModule)
      },
      {
        path: 'add',
        loadChildren: () => import('../src/app/pages/crear-seguimiento/crear-seguimiento.module').then( m => m.CrearSeguimientoPageModule)
      },
      {
        path : 'edit/:idSeguimiento',
        loadChildren: () => import('../src/app/pages/editar-seguimiento/editar-seguimiento.module').then( m => m.EditarSeguimientoPageModule)
      },
      {
        path: 'cliente/:idCliente',
        loadChildren: () => import('../src/app/pages/seguimiento-cliente/seguimiento-cliente.module').then( m => m.SeguimientoClientePageModule)
      },
      {
        path: 'graficos/:idCliente',
        loadChildren: () => import('../src/app/pages/graficos-usuario/graficos-usuario.module').then(m => m.GraficosUsuarioPageModule)
      },
      {
        path: 'editar-admin/:idSeguimiento',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('../src/app/pages/editar-seguimiento-admin/editar-seguimiento-admin.module').then( m => m.EditarSeguimientoAdminPageModule)
      },
      {
        path: 'crear-admin/:idCliente',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('../src/app/pages/crear-seguimiento-admin/crear-seguimiento-admin.module').then( m => m.CrearSeguimientoAdminPageModule)
      },
    ]
  },
  {
    path: 'datos-personales',
    loadChildren: () => import('../src/app/pages/datos-personales/datos-personales.module').then( m => m.DatosPersonalesPageModule)
  },
  {
    path: 'acerca',
    loadChildren: () => import('../src/app/pages/acerca/acerca.module').then( m => m.AcercaPageModule)
  },
  {
    path: 'graficos-usuario',
    loadChildren: () => import('../src/app/pages/graficos-usuario/graficos-usuario.module').then( m => m.GraficosUsuarioPageModule)
  },
  {
    path: 'crear-cuestionario-admin/:idCliente',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../src/app/pages/crear-cuestionario-admin/crear-cuestionario-admin.module').then( m => m.CrearCuestionarioAdminPageModule)
  },
  {
    path: 'configuracion-inicial',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../src/app/pages/configuracion-inicial/configuracion-inicial.module').then( m => m.ConfiguracionInicialPageModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./app/pages/configuracion/configuracion.module').then( m => m.ConfiguracionPageModule)
  },
  {
    path: 'browser-recetas',
    loadChildren: () => import('./app/pages/browser-recetas/browser-recetas.module').then( m => m.BrowserRecetasPageModule)
  },
  {
    path: 'politica-privacidad',
    loadChildren: () => import('./app/politica-privacidad/politica-privacidad.module').then( m => m.PoliticaPrivacidadPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
