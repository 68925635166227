import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async store(sotrageKey: string, value: any)
  {
    const encriptedValue = btoa(escape(JSON.stringify(value)));

    await Storage.set({
      key: sotrageKey,
      value: encriptedValue
    });
  }

  async get(sotrageKey: string)
  {
    const response = await Storage.get({key: sotrageKey});

    if ( response.value )
    {
      return JSON.parse( unescape( atob( response.value ) ) );
    }

    return false;
  }

  async removeItem(sotrageKey: string)
  {
    await Storage.remove({key: sotrageKey});
  }

  async clear()
  {
    await Storage.clear();
  }


} // end class
