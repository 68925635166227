import { Component, OnInit } from '@angular/core';

import { MenuController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import {ConfigConstant} from '../src/app/config/config-consts';
import {StorageService} from '../src/app/services/storage-service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  rootPage: any = '/login';

  public activeUser = 'undefined';
  public activeUserId = 0;
  public activeUserAltura = 0;
  public activeUserPeso = 0;
  public activeUserNombre: string;

  public EsAdmin = false;
  public selectedIndex = 0;
  public appPages = [
 /*    {
      title: 'Inbox',
      url: '/folder/Inbox',
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
    {
      title: 'Favorites',
      url: '/folder/Favorites',
      icon: 'heart'
    },
    {
      title: 'Archived',
      url: '/folder/Archived',
      icon: 'archive'
    },
    {
      title: 'Trash',
      url: '/folder/Trash',
      icon: 'trash'
    },
    {
      title: 'Spam',
      url: '/folder/Spam',
      icon: 'warning'
    } */
  ];

  menuPrincipal: Array<any> = [
    {
      texto: 'Datos Personales',
      icono: 'information-outline',
      link: '/datos-personales',
      disabled : false,
      isAdmin: false
    },
    {
      texto: 'Mi cuestionario',
      icono: 'newspaper-outline',
      link: '/cuestionario',
      disabled : false,
      isAdmin: false
    },
    {
      texto: 'Seguimiento',
      icono: 'pulse-outline',
      link: '/seguimiento',
      disabled : false,
      isAdmin: false
    },
    {
      texto: 'Configuración',
      icono: 'cog',
      link: '/configuracion',
      disabled : false,
      isAdmin: false
    },
    {
      texto: 'Notificaciones',
      icono: 'notifications-outline',
      link: '/home',
      disabled : true,
      isAdmin: false
    },
  ];

  public labels = [];
  public loading = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private storageService: StorageService,
    public menuController: MenuController
  ) {
    this.initializeApp();
  }

  CerrarMenu(){
    this.menuController.toggle();
  } // end openMenu

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.storageService.get(ConfigConstant.SUPER_ADMIN).then((SUPER_ADMIN) => {
        console.log('es admin: ' + SUPER_ADMIN);
        this.EsAdmin = SUPER_ADMIN;
      });
      this.router.navigateByUrl('login');
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
   /*  const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    } */
  }

  async logoutUser(){
    // this.storageService.clear();
    // this.activeUserId = 0;
    // this.activeUser = 'undefined';
    // this.activeUserPeso = 0;
    // this.activeUserAltura = 0;
    if ( window.confirm('¿Estas seguro que deseas salir?')){
      navigator['app'].exitApp();
    }
  }

}
