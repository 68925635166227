import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage-service.service';
import { Subject } from 'rxjs';


export enum nLMS_SERVICES_STATUS{
    subo_porque_deseo = 0,
    dame_historico_lms_en_zona = 1,
    dejame_ver_lo_sucede_ahi = 2
}  
@Injectable({
    providedIn: 'root'
  })
 
export class ConfigConstant
{
    public storageService : StorageService;
    // propiedades
  

    public userCredential = {
      "token": "",
      "phone": "",
      "email": "",
      "code": "",
      "id": "",
      "super_admin": ""
    };
  
    public static SUPER_ADMIN = 'SUPER_ADMIN';
    public static PLATAFORMA_ACTIVA = 'PLATAFORMA_ACTIVA';
    public static CONDITIONS_ACEPTANCE = 'CONDITIONS_ACEPTANCE';
    public static CONFIGURACION_INICIAL = 'CONFIGURACION_INICIAL';
    public static GRAFICOS = 'GRAFICOS';

    public static ALIAS = 'ALIAS';
  

    constructor(){
       // this.LoadDefalutConfigValues();
    }

    async genericSave(valor: any, _ConfigConstant: string){
        console.log('a guardar: ' + valor)
        await this.storageService.store(_ConfigConstant, valor).then(result => {
          console.log("Parametro guardado OK: " + _ConfigConstant + ',  Valor: ' + valor);
        }).catch(e => {
          console.log("error al guardar parametro: " + _ConfigConstant);
        })
      } // end genericSave
   



}
